import React from "react";

import DefaultLayout from "../../../../layouts/default";

const DocBDownloads = () => (
  <DefaultLayout title="Downloads – WiCare|Doc">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-Doc.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|Doc-B</span>
              <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;für Soziale Einrichtungen
                </span>
              </span>
              {/* <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;Bewohnerdokumentation
                </span>
                &nbsp;für Soziale Einrichtungen
              </span> */}
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/mobile/">Mobile</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/technik/">
                Technik
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-b/referenzen/">
                Referenzen
              </a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-doc-b/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-small-bottom">
            <h2>WiCare|Doc Dokumente</h2>
          </section>

          <section>
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doc-b-neu.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc – Bewohner-/Klientendokumentation für Menschen mit
                  Beeinträchtigung
                </h5>
                <p className="boldFliesstext">
                  WiCare|Doc ist die Bewohner-/Klientendokumentation für die
                  Betreuung von Menschen mit Beeinträchtigung. Die Software
                  enthält verschiedene Fachkomponenten und unterstützt somit Ihr
                  interdisziplinäres Team während des ganzen
                  Betreuungsprozesses. Beispiele hierfür sind Elemente wie die
                  Bedarfserhebung inkl. Massnahmenplanung, Zielvereinbarungen
                  und Standortgespräche. Diese können bei Bedarf auf Ihre
                  betrieblichen Gegebenheiten angepasst werden.
                  <br />
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-b/2024-10_WCD_B_Flyer_screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doc-b-word-zu-doc.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">Von Word zu WiCare|Doc</h5>
                <p className="boldFliesstext">
                  In 10 verschiedenen Wohnhäusern und Wohngruppen bietet die
                  «Betreutes Wohnen Elgg AG» strukturierte Wohnformen für
                  Menschen ab dem 8. Lebensjahr bis ins hohe Alter an. Die Die
                  verschiedenen Therapie- und Betreuungstätigkeiten, wie auch
                  die ärztlichen setzten ein Dokumentationssystem voraus, das
                  die Betreuungsvielfalt aufnehmen konnte und ohne grossen
                  Lernaufwand in der Praxis einsetzbar war.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-b/2022-04_WCD_Story_Elgg_Screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/wcd-b-2-jahre-praxis.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc – 2 Jahre Praxisbetrieb – Mitarbeiterumfrage
                  Stiftung Wagerenhof, Uster
                </h5>
                <p className="boldFliesstext">
                  Nachdem im Jahr 2019 die Software WiCare|Doc produktiv in
                  Betrieb genommen wurde, führte der Wa- gerenhof nach knapp
                  zwei Jahren Praxiserfahrung eine interne Benutzerumfrage
                  durch. Wir freuen uns sehr, Ihnen das positive Ergebnis
                  präsentieren zu dürfen.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-b/2022-01_WS_Mitarbeiterbefragung_Wagerenhof_WCD_B.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doc-b-story-alpenruhe.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  Transparenz im Dienste der Klienten – WiCare|Doc in der
                  Stiftung Alpenruhe, Saanen
                </h5>
                <p className="boldFliesstext">
                  Hinter dem beschaulichen Namen «Alpenruhe» verbirgt sich mit
                  42 Wohn- und 55 Arbeits- und Beschäftigungsplätzen eine
                  dynamische Organisation mit dem Blick für die wesentlichen
                  Ansprüche der Organisationsentwicklung im Dienste bedürftiger
                  Menschen.
                  <br />
                  <br />
                  Der Entscheid für die Zusammenarbeit mit der WigaSoft AG
                  basierte auf den grundsätzlichen Möglichkeiten, welche die
                  Software WiCare|Doc für den Einsatz in den vorhandenen
                  Strukturen bietet. Hinzu kam die Erfahrung der WigaSoft AG in
                  der Umsetzung von Projekten, die eine intensive
                  Auseinandersetzung mit den spezifischen Alltagsroutinen und
                  den damit verbundenen Prozessen verlangen.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-b/WCD-B_Story_Stiftung_Alpenruhe.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-bottom has-padding-top">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doc-b-story.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  Erfolgreich eingeführt - WiCare|Doc im Wagerenhof in Uster
                </h5>
                <p className="boldFliesstext">
                  Die Stiftung Wagerenhof in Uster bietet 237 Menschen mit
                  kognitiver und schwerer körperlicher Beeinträchtigung in 34
                  Wohngruppen ein bleibendes Zuhause mit Tagesstruktur und
                  Ausbildungsplätzen, einem 24h Pflegedienst und Therapien. In
                  der umfassenden gesundheitlichen und agogischen Betreuung wird
                  dabei grössten Wert auf adäquate Behandlung und optimale
                  Prozesse gelegt. Im Zentrum steht die kontinuierliche und
                  qualitativ nachhaltige Entwicklung für alle Beteiligten.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-b/WCD_Story-Stiftung-Wagerenhof-screen_2020-11.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
          </section>
        </div>
            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    WiCare|Doc-B
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-b/downloads/">
                    Downloads
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default DocBDownloads;
